<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>
        {{ tournament.name }}

        <ion-badge>{{ tournament.dates }}</ion-badge>
      </ion-card-title>
    </ion-card-header>

    <ion-card-content style="overflow-y: auto; max-height: 33vh">
      <div v-if="!expanded">
        <a href @click.prevent="expanded = true">Show tournament results</a>
      </div>
      <table class="w-100" v-if="expanded">
        <tbody>
          <tr v-for="ranking of rankings" :key="ranking.id">
            <td v-if="!ranking.pick_id">--</td>
            <td v-if="!ranking.pick_id">
              {{ ranking.user.display_name }} (No Pick)
            </td>
            <td v-if="!ranking.pick_id">--</td>
            <td v-if="ranking.pick_id">{{ ranking.result_place }}</td>
            <td v-if="ranking.pick_id">
              {{ ranking.user.display_name }} ({{ ranking.golfer_first_name }}
              {{ ranking.golfer_last_name }})
            </td>
            <td v-if="ranking.pick_id">
              <template
                :name="ranking.result_place ? 'span' : 'del'"
                class="text-muted"
              >
                {{ ranking.result_score > 0 ? "+" : ""
                }}{{ ranking.result_score }}
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonBadge,
} from "@ionic/vue";
import axios from "axios";

export default {
  props: ["tournament", "leagueId"],
  data: () => ({
    rankings: null,
    expanded: false,
  }),
  watch: {
    expanded() {
      if (this.expanded && !this.rankings) {
        this.loadRankings();
      }
    },
  },
  components: {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonBadge,
  },
  methods: {
    async loadRankings() {
      const rankingsRequest = await axios.get(
        `/tournaments/${this.leagueId}/${this.tournament.id}/rankings`
      );

      this.rankings = Object.values(rankingsRequest.data.rankings);
    },
  },
};
</script>
