<template>
  <div v-if="pastTournaments && pastTournaments.length">
    <h3 class="ion-padding-horizontal mt-3" id="history">Past tournaments</h3>
    <past-tournament
      v-for="tournament of pastTournaments"
      :tournament="tournament"
      :leagueId="leagueId"
      :key="tournament.id"
    />
  </div>
</template>

<script>
import axios from "axios";
import PastTournament from "@/components/tournaments/PastTournament.vue";
export default {
  components: { PastTournament },
  props: ["leagueId", "seasonId"],
  data: () => ({
    pastTournaments: null,
  }),
  watch: {
    seasonId() {
      this.pastTournaments = null;
      this.loadTournaments();
    },
  },
  methods: {
    async loadTournaments() {
      const request = await axios.get(
        "/tournaments/" +
          this.leagueId +
          "/ended" +
          (this.seasonId ? "/" + this.seasonId : ""),
        {
          params: { offset: this.offset },
        }
      );
      this.pastTournaments = Object.values(request.data.past_tournaments);
    },
  },
  mounted() {
    this.loadTournaments();
  },
};
</script>
