<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>LEAGUE ANNOUNCEMENTS</ion-card-title>
    </ion-card-header>

    <ion-card-content>
      <div v-html="league.parsed_announcement"></div>
      <small>
        {{ formatAsDiff(league.announced_at) }} - League Owner,
        {{ league.owner.name }}
      </small>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/vue";
import moment from "moment";
export default {
  components: {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
  },
  props: ["league"],
  methods: {
    formatAsDiff(timestamp) {
      return moment(timestamp).fromNow();
    },
  },
};
</script>
