<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>
        {{ tournament.name }}
        <ion-badge>Picking</ion-badge>
      </ion-card-title>
    </ion-card-header>

    <ion-card-content style="overflow-y: auto; max-height: 33vh">
      <div v-if="picks.length">
        <table class="w-100">
          <tbody>
            <tr class="hover-highlight" v-for="pick of picks" :key="pick.id">
              <td class="ps-2">{{ pick.golfer.name }}</td>
              <td class="pe-2" align="right">
                <span v-if="pick.golfer.pick_used">PICKED</span>
                <ion-button size="small" @click="removePick(pick.golfer)"
                  >REMOVE PICK</ion-button
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p v-if="!expand">
          <ion-button @click="openPickNow">Pick Now</ion-button>
        </p>
        <table class="w-100" v-else>
          <p v-if="golfers == null">Loading...</p>
          <tbody>
            <tr
              class="hover-highlight"
              v-for="golfer of golfers"
              :key="golfer.id"
            >
              <td class="ps-2">{{ golfer.name }}</td>
              <td class="pe-2" align="right">
                <span v-if="golfer.pick_used">USED</span>
                <ion-button
                  v-if="!golfer.pick_used"
                  size="small"
                  @click="pick(golfer)"
                  >PICK</ion-button
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script>
import eventBus from "@/bus";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonBadge,
} from "@ionic/vue";
import axios from "axios";

export default {
  props: ["tournament", "leagueId"],
  data: () => ({
    golfers: null,
    picks: [],
    expand: false,
  }),
  components: {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonBadge,
    IonButton,
  },
  computed: {
    pickGolferIds() {
      return this.picks.map((pick) => pick.golfer_id);
    },
  },
  methods: {
    openPickNow() {
      this.expand = true;
      this.loadGolfers();
    },
    async pick(golfer) {
      if (golfer.pick_used) return;
      if (this.pickGolferIds.indexOf(golfer.id) == -1) {
        axios
          .post(`/picks`, {
            golfer_id: golfer.id,
            tournament_id: this.tournament.id,
            pick_number: 1,
            status: "drafted",
          })
          .then(() => {
            eventBus.$emit("picksUpdated");
          })
          .catch((e) => {
            console.log("e.response", e.response);
            if (e.response && e.response.data.message) {
              this.$notify({
                type: "error",
                text: e.response.data.message,
              });
            } else {
              this.$notify({
                type: "error",
                text: "An error occurred, please try again later",
              });
            }
          });
      } else {
        this.removePick(golfer);
      }
    },
    removePick(golfer) {
      let pick = this.picks.find((p) => p.golfer_id == golfer.id);
      if (!pick) {
        return;
      }
      axios.delete(`/picks/${pick.id}`).then(() => {
        eventBus.$emit("picksUpdated");
      });
    },
    async loadGolfers() {
      const rankingsRequest = await axios.get(`/golfers/${this.tournament.id}`);

      this.golfers = rankingsRequest.data;
    },
    async loadPicks() {
      const response = await axios.get(`/picks/${this.tournament.id}`);
      this.picks = response.data;
    },
  },
  mounted() {
    eventBus.$on("picksUpdated", () => {
      this.loadPicks();
      this.loadGolfers();
    });
    this.loadPicks();
  },
  beforeUnmount() {
    eventBus.$off("picksUpdated");
  },
};
</script>

<style scoped>
.hover-highlight:hover {
  background-color: #ccc3;
}
</style>