<template>
  <main-layout pageTitle="Leagues">
    <announcement v-if="league && league.announcement" :league="league" />

    <div v-if="draftingTournaments && draftingTournaments.length && leagueId">
      <h3 class="ion-padding-horizontal">Drafting tournaments</h3>
      <draft-picker
        :league-id="leagueId"
        v-for="tournament of draftingTournaments"
        :key="tournament.id"
        :tournament="tournament"
      />
    </div>
    <div v-if="activeTournaments && activeTournaments.length && leagueId">
      <h3 class="ion-padding-horizontal">Active tournaments</h3>
      <active-tournament
        :league-id="leagueId"
        v-for="tournament of activeTournaments"
        :key="tournament.id"
        :tournament="tournament"
      />
    </div>

    <div v-if="upcomingTournaments && upcomingTournaments.length">
      <h3 class="ion-padding-horizontal">Upcoming tournaments</h3>
      <tournament-list :tournaments="upcomingTournaments" />
    </div>

    <past-tournament-list :leagueId="leagueId" v-if="loadPastTournaments" />
  </main-layout>
</template>

<script>
import axios from "axios";
import TournamentList from "../components/tournaments/TournamentList.vue";
import ActiveTournament from "@/components/tournaments/ActiveTournament.vue";
import DraftPicker from "@/components/tournaments/DraftPicker.vue";
import leagueModule from "../modules/leagues";
import Announcement from "@/components/leagues/Announcement.vue";
import PastTournamentList from "@/components/tournaments/PastTournamentList.vue";

export default {
  components: {
    TournamentList,
    ActiveTournament,
    DraftPicker,
    Announcement,
    PastTournamentList,
  },
  data: () => ({
    leagueId: null,
    league: null,
    upcomingTournaments: null,
    draftingTournaments: null,
    activeTournaments: null,
    pastTournaments: null,
  }),
  watch: {
    $route() {
      this.leagueId = this.$route.params.league_id;
    },
  },
  async mounted() {
    this.leagueId = this.$route.params.league_id;
    leagueModule.setCurrentLeague(this.leagueId);
    const request = await axios.get("/tournaments/" + this.leagueId, {
      params: { offset: this.offset },
    });
    this.league = (await axios.get("/league/" + this.leagueId)).data;
    this.upcomingTournaments = Object.values(request.data.upcoming_tournaments);
    this.draftingTournaments = Object.values(request.data.drafting_tournaments);
    this.activeTournaments = Object.values(request.data.active_tournaments);

    this.loadPastTournaments = true;
  },
};
</script>
