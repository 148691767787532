<template>
  <div v-if="tournaments">
    <ion-list>
      <ion-item v-for="tournament of tournaments" :key="tournament.id">
        <ion-label>{{ tournament.name }}</ion-label>
        <ion-badge>{{ tournament.dates }}</ion-badge>
      </ion-item>
    </ion-list>
  </div>
  <p v-else>Loading....</p>
</template>

<script>
import { IonItem, IonList, IonLabel, IonBadge } from "@ionic/vue";

export default {
  props: ["tournaments"],
  data: () => ({
    offset: 0,
  }),
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonBadge,
  },
};
</script>
